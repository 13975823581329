

import VueRouter from 'vue-router';
const routes =[
    {
        path:'/',
        name:'login',
        component:()=>import('../components/Login')
    },
    {
        path:'/Index',
        name:'index',
        component:()=>import('../components/Index'),
        children:[
            {
                path:'/Home',
                name:'首页',
                meta:{
                    title:'首页'
                },
                component:()=>import('../components/Home'),
            },
        ]
    }
]


const router = new VueRouter({
    mode:'history',
    routes
})
export function resetRouter() {
    router.matcher = new VueRouter({
        mode:'history',
        routes: []
    }).matcher
}

// 在路由文件中定义全局前置守卫
router.beforeEach((to, from, next) => {
    const CurUser = sessionStorage.getItem('CurUser'); // 从sessionStorage中获取用户信息
    if (to.path !== '/' && !CurUser) { // 如果用户未登录并且访问的不是登录页
        next('/'); // 跳转到登录页
    }
    else {
        next(); // 放行
    }
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router;

