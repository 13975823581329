import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css';
import axios from "axios";
import VueRouter from 'vue-router';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import $ from 'jquery'
import Popper from 'popper.js'
import md5 from 'js-md5';
import xlsx from 'xlsx';

window.$ = $
window.Popper = Popper


Vue.prototype.$axios =axios;
Vue.prototype.$httpUrl='http://localhost:8090'
// Vue.prototype.$httpUrl='https://mqtt.9958.love:8090'
// Vue.prototype.$httpUrl='http://10.102.129.128:8090'


Vue.config.productionTip = false
Vue.prototype.$md5 = md5;
// Vue.use(ElementUI,{size:'small'});

Vue.use(ElementUI);
Vue.use(VueRouter);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
